export const environment = {
  apiURL: 'https://us-central1-registry-staging-401515.cloudfunctions.net/api',
  authMode: 'redirect',
  firebaseConfig: {
    apiKey: 'AIzaSyD3s84rEMZw6hkhr0M3NlFLNpUvzcb9ECg',
    authDomain: 'registry-admin-ui-staging.devintent.com',
    projectId: 'registry-staging-401515',
    storageBucket: 'registry-staging-401515.appspot.com',
    messagingSenderId: '983603864756',
    appId: '1:983603864756:web:86b94bbb9bbf913b35e3e1',
    measurementId: 'G-7Z6RJTZFVC',
  },
  allowedDomain: 'devintent.com',
};
