import { Component, inject, OnInit } from '@angular/core';
import { FirebaseError } from '@angular/fire/app';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ErrorsDialogComponent } from '../../shared/errors-dialog/errors-dialog.component';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [RouterModule, MatButtonModule, MatIconModule],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss',
})
export class AuthComponent implements OnInit {
  private readonly authService: AuthService = inject(AuthService);
  private readonly router: Router = inject(Router);
  private readonly dialog = inject(MatDialog);

  async ngOnInit(): Promise<void> {
    // Handle redirect result when the component initializes
    if (environment.authMode === 'redirect') {
      try {
        await this.authService.handleRedirectResult();
      } catch (error) {
        this.handleAuthError(error as FirebaseError);
      }
    }
  }

  async authenticateWithGoogle(): Promise<void> {
    // TODO:
    // Implement a NotificationService to handle success, error and warn info notifications
    // ClickUp task: https://app.clickup.com/t/86a2j2gqf
    try {
      await this.authService.authenticateWithGoogle();
      this.router.navigateByUrl('/');
    } catch (error) {
      this.handleAuthError(error as FirebaseError);
    }
  }

  private handleAuthError(error: FirebaseError): void {
    // Log the error for debugging
    console.error('Error during Google authentication:', error);
    // Display the error to the user
    this.dialog.open(ErrorsDialogComponent, {
      data: {
        action: 'Authentication',
        error: this.authService.getErrorMessage(error),
      },
    });
  }
}
