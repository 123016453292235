<h1 mat-dialog-title>{{ data.action | titlecase }}</h1>
<div mat-dialog-content>
  <p>Errors occurred during {{ data.action | lowercase }}:</p>
  <ul>
    @if (multipleErrors) {
      @for (error of data.error; track $index) {
        <li>{{ error }}</li>
      }
    } @else {
      <p>{{ data.error }}</p>
    }
  </ul>
  <div mat-dialog-actions align="end">
    <button id="dismiss-button" mat-button cdkFocusInitial (click)="dialogRef.close()">OK</button>
  </div>
</div>
