import { LowerCasePipe, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';

export interface ErrorsDialogData {
  readonly action: string;
  readonly error: string | string[];
}

@Component({
  selector: 'app-errors-dialog',
  standalone: true,
  imports: [MatButtonModule, MatDialogActions, MatDialogTitle, MatDialogContent, LowerCasePipe, TitleCasePipe],
  templateUrl: './errors-dialog.component.html',
  styleUrl: './errors-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorsDialogComponent {
  readonly dialogRef = inject(MatDialogRef<ErrorsDialogComponent>);
  readonly data: ErrorsDialogData = inject(MAT_DIALOG_DATA);
  protected readonly multipleErrors: boolean = Array.isArray(this.data.error);
}
